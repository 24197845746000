<template>
    <section class="mange-stage d-flex flex-column bg-dark vh-100"
             ref="mangaStage"
             v-bind:class="{ 'is-rtl': isRTL, 'is-vertical': isVertical }">
        <swiper class="swiper w-100 flex-grow-1"
                ref="serviceSwiper"
                v-bind:options="swiperOptions"
                v-on:slideChange="slideChange">
            <cover v-bind:cover="cover"></cover>
            <page v-for="(link, index) in links"
                  v-bind:key="index"
                  v-bind:page="link"
                  v-bind:index="index">
            </page>


            <div class="swiper-scrollbar"
                 slot="scrollbar">
            </div>
            <button class="position-absolute btn button-nav button-next d-flex justify-content-center align-items-center bg-light text-dark rounded-circle border shadow-sm"
                    slot="button-next"
                    v-if="!isVertical"
                    v-on:click="next">
                <font-awesome-icon v-if="isRTL"
                                   v-bind:icon="['far', 'chevron-left']">
                </font-awesome-icon>
                <font-awesome-icon v-else
                                   v-bind:icon="['far', 'chevron-right']">
                </font-awesome-icon>
            </button>
            <button class="position-absolute btn button-nav button-prev d-flex justify-content-center align-items-center bg-light text-dark rounded-circle border shadow-sm"
                    slot="button-prev"
                    v-if="!isVertical"
                    v-on:click="prev">
                <font-awesome-icon v-if="!isRTL"
                                   v-bind:icon="['far', 'chevron-left']">
                </font-awesome-icon>
                <font-awesome-icon v-else
                                   v-bind:icon="['far', 'chevron-right']">
                </font-awesome-icon>
            </button>
        </swiper>
        <div class="controls d-flex justify-content-center">
            <button class="btn btn-sm btn-dark mr-2"
                    v-show="!isFullscreen"
                    v-on:click="enableFullscreen">
                <font-awesome-icon class="mr-1"
                                   v-bind:icon="['far', 'expand-wide']">
                </font-awesome-icon> Fullscreen
            </button>
            <button class="btn btn-sm btn-dark mr-2"
                    v-show="isFullscreen"
                    v-on:click="disableFullscreen">
                <font-awesome-icon class="mr-1"
                                   v-bind:icon="['far', 'compress-wide']">
                </font-awesome-icon> Exit fullscreen
            </button>

            <button class="btn btn-sm btn-dark mr-2"
                    v-show="!isVertical"
                    v-on:click="toggleVerticalMode">
                <font-awesome-icon class="mr-1"
                                   v-bind:icon="['far', 'arrows-v']">
                </font-awesome-icon> Vertical
            </button>
            <button class="btn btn-sm btn-dark mr-2"
                    v-show="isVertical"
                    v-on:click="toggleVerticalMode">
                <font-awesome-icon class="mr-1"
                                   v-bind:icon="['far', 'arrows-h']">
                </font-awesome-icon> Horizontal
            </button>
        </div>
    </section>
</template>

<script>
import SwiperInstance from "../init/swiper";
const { Swiper } = SwiperInstance;

import "swiper/swiper-bundle.min.css";

import getObjectFromPublicGateway from "../api/getObjectFromPublicGateway";
import Cover from "./MangaStage/Cover";
import Page from "./MangaStage/Page";

export default {
    name: "MangaStage",
    components: {
        Page,
        Cover,
        Swiper,
    },
    props: {
        cid: {
            type: String,
        },
    },
    data () {
        return {
            verticalSwiperOptions: {
                direction: "vertical",
                freeMode: true,
            },
            horizontalSwiperOptions: {
                breakpoints: {
                    1024: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                },
            },

            isVertical: false,
            isFullscreen: false,
            isRTL: true,
            cover: "",
            links: [],
        };
    },
    computed: {
        stage () {
            return this.$refs?.mangaStage;
        },
        swiperOptions () {
            return Object.assign(
                {},
                {
                    slidesPerGroupSkip: 1,
                    spaceBetween: 0,

                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    scrollbar: {
                        el: ".swiper-scrollbar",
                        draggable: true,
                    },
                    mousewheel: {
                        enabled: true,
                    },
                    keyboard: {
                        enabled: true,
                    },
                    zoom: {
                        enabled: true,
                        maxRatio: 5,
                    },
                    /*preloadImages: false,*/
                    lazy: {
                        checkInView: true,
                        loadPrevNext: true,
                        loadPrevNextAmount: 6,
                    },
                    watchSlidesVisibility: true,
                },
                this.isVertical ? this.verticalSwiperOptions : this.horizontalSwiperOptions
            );
        },
        swiperInstance () {
            return this.$refs?.serviceSwiper?.$swiper;
        },
    },
    methods: {
        slideChange (/*evt*/) {
            //console.log(evt);
        },
        next () {
            this.swiperInstance.slideNext();
        },
        prev () {
            this.swiperInstance.slidePrev();
        },
        toggleFullscreen () {
            if (this.isFullscreen === true) {
                this.enableFullscreen();
                return;
            }
            this.disableFullscreen();
        },
        async enableFullscreen () {
            try {
                await this.stage.requestFullscreen(
                    {

                    }
                );
            } catch (e) {
                console.error(e);
                return;
            }
            this.isFullscreen = true;
        },
        async disableFullscreen () {
            try {
                await document.exitFullscreen();
            } catch (e) {
                console.error(e);
                return;
            }
            this.isFullscreen = false;
        },
        toggleVerticalMode () {
            this.isVertical = !this.isVertical;
            this.swiperInstance
                .changeDirection(
                    this.isVertical ? "vertical" : "horizontal"
                );
        },
    },
    async mounted () {
        if (this.cid) {
            let links;
            try {
                links = await getObjectFromPublicGateway(this.cid)
            } catch (e) {
                console.error(e);
            }
            this.cover = links[0];
            this.links = links.slice(1);
        }
    },
    watch: {
        async cid (newVal) {
            if (newVal) {
                let links;
                try {
                    links = await getObjectFromPublicGateway(newVal)
                } catch (e) {
                    console.error(e);
                }
                this.cover = links[0];
                this.links = links.slice(1);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.mange-stage {
    .controls {

    }

    .button-nav {
        width: 40px;
        height: 40px;

        top: 50%;
        margin-top: -15px;

        z-index: 1;

        &.button-next {
            right: 10px;
        }

        &.button-prev {
            left: 10px;
        }
    }

    &.is-rtl {
        .swiper {
            direction: rtl;
        }

        .button-nav {
            &.button-next {
                right: initial;
                left: 10px;
            }

            &.button-prev {
                left: initial;
                right: 10px;
            }
        }
    }

    &.is-vertical {

    }
}
</style>
