import { library } from "@fortawesome/fontawesome-svg-core";

import {

} from "@fortawesome/pro-solid-svg-icons";

import {
    faBars as faBarsRegular,
    faChevronDown as faChevronDownRegular,
    faTimes as faTimesRegular,
    faChevronLeft as faChevronLeftRegular,
    faChevronRight as faChevronRightRegular,
    faArrowLeft as faArrowLeftRegular,
    faExpandWide as faExpandWideRegular,
    faCompressWide as faCompressWideRegular,
    faArrowsV as faArrowsVRegular,
    faArrowsH as faArrowsHRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {

} from "@fortawesome/pro-light-svg-icons";

import {

} from "@fortawesome/free-brands-svg-icons";

library.add(
    faBarsRegular,
    faChevronDownRegular,
    faTimesRegular,
    faChevronLeftRegular,
    faChevronRightRegular,
    faArrowLeftRegular,
    faExpandWideRegular,
    faCompressWideRegular,
    faArrowsVRegular,
    faArrowsHRegular,
);
