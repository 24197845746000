<template>
    <swiper-slide style="min-height: 100vh"
                  v-bind:class="{ 'h-auto': isVertical, }">
        <div class="manga-page-cover d-flex justify-content-center align-items-center w-100"
             v-bind:class="{ 'h-100': !isVertical, }">
            <img class="mw-100"
                 alt=""
                 v-if="cover"
                 v-bind:class="{ 'mh-100': !isVertical, }"
                 v-bind:src="`${ gateway(cover) }#x-ipfs-companion-no-redirect`">
        </div>
    </swiper-slide>
</template>

<script>
import random from "lodash/random";
import { Gateways, } from "../../config";

import Swiper from "../../init/swiper";
const { SwiperSlide } = Swiper;

export default {
    name: "Cover",
    components: {
        SwiperSlide,
    },
    props: {
        isVertical: {
            type: Boolean,
            default: false,
        },
        cover: {
            type: String,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        gateway () {
            return Gateways[random(0, Gateways.length - 1)];
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.manga-page-cover {

}
</style>
