<template>
    <div class="home">
        <manga-stage v-bind:cid="targetCID"></manga-stage>

        <div class="container py-5">
            <div class="row">
                <div class="col-7">

                </div>
                <div class="col-5">
                    <div class="card shadow-sm">
                        <div class="card-body" style="height: 500px">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MangaStage from "../components/MangaStage";

export default {
    name: "Home",
    metaInfo: {
        title: "TEST",
    },
    components: {
        MangaStage,
    },
    props: {

    },
    data () {
        return {

        };
    },
    computed: {
        targetCID () {
            return this.$route.query.cid ?? "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
