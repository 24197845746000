import CID from "cids";

export function cidV0Transformer (gateway) {
    return path => `https://${ gateway }${ path }`
}

export function cidV1Transformer (gateway) {
    return path => {
        const cid = new CID(
            path.replace(/\/ip(f|n)s\//, "").replace(/\//, "")
        );
        return `https://${ cid.toV1() }${ gateway }`;
    }
}

export const Gateways = [
    cidV0Transformer("gateway.ipfs.io/ipfs"),
    cidV0Transformer("ipfs.io/ipfs"),
    cidV1Transformer(".ipfs.dweb.link"),

    cidV0Transformer("cloudflare-ipfs.com/ipfs"),
    //cidV1Transformer(".ipfs.infura-ipfs.io"),

    //cidV0Transformer("ipfs.globalupload.io"),
    cidV0Transformer("10.via0.com/ipfs"),

    // SLOW
    //cidV0Transformer("ipfs.taxi/ipfs"),

    // Rate limiting
    //cidV0Transformer("gateway.pinata.cloud/ipfs"),

    //"https://ninetailed.ninja",
    //"https://video.oneloveipfs.com",
    //"https://trusted-setup.filecoin.io",
];

// https://<CIDv1>.ipfs.dweb.link/
//https://<CIDv1>.ipfs.infura-ipfs.io/
