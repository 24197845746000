<template>
    <swiper-slide style="min-height: 100vh"
                  v-bind:class="{ 'h-auto': isVertical, }">
        <div class="swiper-zoom-container manga-page-wrapper d-flex justify-content-center align-items-center w-100"
             v-bind:class="pageWrapperClass">
            <img class="swiper-lazy mw-100"
                 alt=""
                 decoding="async"
                 referrerpolicy="no-referrer"
                 v-bind:class="{ 'mh-100': !isVertical, }"
                 v-bind:data-src="`${ gateway(page) }#x-ipfs-companion-no-redirect`"
                 v-on:error="handleError">
        </div>
        <div class="swiper-lazy-preloader"></div>
    </swiper-slide>
</template>

<script>
import random from "lodash/random";
import { Gateways, } from "../../config";

import Swiper from "../../init/swiper";
const { SwiperSlide } = Swiper;

function isOdd (x) {
    return x & 1;
}

export default {
    name: "Page",
    components: {
        SwiperSlide,
    },
    props: {
        isVertical: {
            type: Boolean,
            default: false,
        },
        page: {
            type: String,
        },
        index: {
            type: Number,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        pageWrapperClass () {
            return {
                "align-right": isOdd(this.index) && !this.isVertical,
                "align-left": !isOdd(this.index) && !this.isVertical,
                "h-100": !this.isVertical,
            };
        },
        gateway () {
            return Gateways[random(0, Gateways.length - 1)];
        },
    },
    methods: {
        handleError (evt) {
            console.log(evt);
        },
    },
}
</script>

<style lang="scss" scoped>
.manga-page-wrapper {
    @media (min-width: 1024px) {
        &.align-left {
            justify-content: flex-end !important;
        }

        &.align-right {
            justify-content: flex-start !important;
        }
    }
}
</style>
