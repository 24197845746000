import SwiperCore, { Navigation, Scrollbar, Keyboard, Mousewheel, Zoom, Lazy } from "swiper";
SwiperCore.use(
    [
        Navigation,
        Scrollbar,
        Keyboard,
        Mousewheel,
        Zoom,
        Lazy,
    ]
);
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
export default getAwesomeSwiper(SwiperCore);
