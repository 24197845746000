const LayoutStore = {
    namespaced: true,
    state: {
        offline: false,

        updateAvailable: false,
        updatedServiceWorker: null,
    },
    getters: {
        isUpdateAvailable ({ updateAvailable }) {
            return updateAvailable;
        },
        serviceWorker ({ updatedServiceWorker }) {
            return updatedServiceWorker;
        },
        isOnline ({ offline }) {
            return !offline;
        },
    },
    mutations: {
        updateAvailable (state, updatedServiceWorker) {
            state.updateAvailable = true;
            state.updatedServiceWorker = updatedServiceWorker;
        },
        online (state) {
            state.offline = false;
        },
        offline (state) {
            state.offline = true;
        }
    },
};

export default LayoutStore;
