import { Gateways, } from "../config";

export async function getObjectFromPublicGateway (cid) {
    const response = await Promise.any(
        Gateways.map(
            transformer => fetch(
                `${ transformer(`/${ cid }`) }#x-ipfs-companion-no-redirect`,
                {
                    "method": "GET",
                }
            )
        )
    );
    console.log(response);

    if (response.ok) {
        const result = await response.text();
        if (result) {
            const parser = new DOMParser();
            const dom = parser.parseFromString(result, "text/html");
            const links = dom
                .body
                .querySelectorAll("#content tr a.ipfs-hash");
            return new Array(...links)
                .map(
                    ({ pathname }) => pathname.replace(/\/ip(f|n)s/, "")
                );
        }
        throw "Internal server error";
    }

    if (response.statusCode >= 400) {
        throw response.body;
    }

    throw "Internal server error";
}

export default getObjectFromPublicGateway;
