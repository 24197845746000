<template>
    <div id="app"
         class="bg-light">
        <router-view></router-view>

        <b-toast no-auto-hide
                 solid
                 v-bind:visible="isUpdateAvailable">
            <template v-slot:toast-title>
                <strong>
                    Update available
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    Click to update👉
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-primary px-4"
                            v-on:click="updateApp">
                        Refresh
                    </button>
                </div>
            </div>
        </b-toast>

        <b-toast no-auto-hide
                 solid
                 v-bind:visible="isOffline">
            <template v-slot:toast-title>
                <strong>Site offline</strong>
            </template>
            The site is now offline. Information may become stale.
        </b-toast>
    </div>
</template>

<script>
import {
    BToast,
} from "bootstrap-vue";
export default {
    metaInfo: {
        titleTemplate: '%s | IPFS Gallery',
    },
    components: {
        BToast,
    },
    data () {
        return {

        };
    },
    computed: {
        userStatus () {
            return this.$store.getters["user/status"];
        },
        isOffline () {
            return !this.$store.getters["layout/isOnline"];
        },
        isUpdateAvailable () {
            return this.$store.getters["layout/isUpdateAvailable"];
        },
        serviceWorker () {
            return this.$store.getters["layout/serviceWorker"];
        },
    },
    methods: {
        updateApp () {
            if (navigator.serviceWorker) {
                if (this.serviceWorker) {
                    let refreshing = false;
                    navigator.serviceWorker.addEventListener(
                        "controllerchange",
                        () => {
                            if (refreshing) {
                                return;
                            }
                            window.location.reload();
                            refreshing = true;
                        }
                    );

                    this.serviceWorker.postMessage({
                        "type": "SKIP_WAITING"
                    });
                }
            }
        },
    },
    created () {

    },
    mounted () {
        window.addEventListener("online", () => this.$store.commit("layout/online"));
        window.addEventListener("offline", () => this.$store.commit("layout/offline"));
    },
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family:
        "Lato",
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        "Microsoft JhengHei",
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;
}
</style>
